<template>
    <div class="add-video">
        <h3 class="v-h3 add-video__title">Добавление ссылки на видео</h3>
        <input v-model="videoLink" class="add-video__input form-control" type="text" placeholder="Ссылка на видео">
        <div v-show="videoId" class="add-video__iframe">
            <youtube width="100%" style="margin-top: 20px;" ref="youtube" :video-id="videoId"></youtube>
        </div>
        <div class="add-video__controls">
            <button @click="addVideo" class="v-btn">Добавить</button>
            <button @click="$emit('close')" class="v-btn v-btn--gray ml-10">Отменить</button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AddVideoModal',
  props: {
    onAdd: {
      type: Function
    }
  },
  data() {
    return {
      videoLink: '',
      videoId: null
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
    /* videoId () {
            return this.$youtube.getIdFromUrl(this.videoLink);
        } */
  },
  watch: {
    videoLink(newValue, oldValue) {
      const vId = this.$youtube.getIdFromUrl(newValue)
      console.log(this.$refs.youtube.player.getOptions().then(result => console.log(result)))
      console.log(this.$refs.youtube)
      this.videoId = vId || null
        console.log(this.videoId)
    }
  },
  methods: {
    addVideo() {
      if (this.videoId) {
        this.$nextTick(() => {
          const embedCode = `<iframe src="https://www.youtube.com/embed/${this.videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
          this.onAdd(embedCode)
          this.$emit('close')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.add-video {
    padding: 30px;
    &__input {
        margin-top: 40px;
    }
    &__iframe {
        width: 100%;
        margin-top: 20px;
    }
    &__controls {
        margin-top: 50px;
    }
}
</style>
